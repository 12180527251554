import { EventBase } from '../types';

export interface Transport {
    getUrl(): string;
    /** Submits the event to Sentry */
    sendEvent<E extends EventBase>(event: E): void;
}

/** `fetch` based transport */
export abstract class BaseTransport implements Transport {
    constructor(private url: string) { }

    getUrl(): string {
        return this.url;
    }

    sendEvent<E extends EventBase>(event: E): void {
        throw new Error("Method not implemented.");
    }
}
