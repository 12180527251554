import { EventBase } from '../types';
import { BaseTransport } from './transport';

/** `fetch` based transport */
export default class ImageTransport extends BaseTransport {
  sendEvent<E extends EventBase>(event: E): void {
    const eventJson = JSON.stringify(event).replace(/\/#/g,'');
    const url = `${this.getUrl()}?event=${encodeURIComponent(eventJson)}`;

    let newImg: HTMLImageElement | null = new Image();
    newImg.onload = function () {
      newImg = null;
    }
    newImg.src = url;
  }
}
