import { BehaviorClient } from './client/behavior/behaviorclient';

import { getCurrentBehaviorHub } from './hub/behaviorHub';
import { Integration, Options } from './types';
import ImageTransport from './transport/imageTransport';
import { logger } from './utils';

export * from './behaviorsdk';

export interface Config extends Options {
    dsn: string,
    integrations?: Array<Integration>
}

const installedIntegrations: string[] = [];

/** Setup given integration */
function setupIntegration(integration: Integration): void {
    if (installedIntegrations.indexOf(integration.name) !== -1) {
        return;
    }
    integration.setupOnce();
    installedIntegrations.push(integration.name);
    logger.log(`Integration installed: ${integration.name}`);
}

/**
 * partial init
 */
export function init(options: Config): void {
    // init every hubs
    const transport = new ImageTransport(options.dsn);
    getCurrentBehaviorHub().bindClient(new BehaviorClient(transport, options));
    options.integrations && options.integrations.map(integration => setupIntegration(integration))
}